import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonParentSection from './CommonParentSection';
import * as compareAction from '../../../actions/compareAction';
import { HomePageVehicleLoader } from '../../../common_components/Loader/Index';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BiRupee } from 'react-icons/bi';
import { Button, PriceConvertor } from '../../../common_components/CommonFields';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import Analytics from '../../../common_components/Common/Analytics';
import Image from 'next/image';
import Link from 'next/link';

export default function VehicleCompareCard({ type }) {
  const dispatch = useDispatch();
  let { comparevehicles } = useSelector(
    (state) => state.home
  );

  const { setCompareVehicleId } = bindActionCreators(
    compareAction,
    dispatch
  );
  const { compareVehicle } = useSelector(
    (state) => state.compare
  );

  const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL_IMAGES;
  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [count, setCount] = useState(4);
  const [Limit, setLimit] = useState(10);
  const [current, setcurrent] = useState(7);
  const [slicesize, setSliceSize] = useState(8);
  const Router = useRouter();


  useEffect(() => {
    if (count % 7 === 0 && count < 14) {
      if (count >= current) {
        setSliceSize(slicesize + 8);
        setLimit(Limit + 6)

        setcurrent(count);
      }
      else {
        setSliceSize(slicesize - 8)
        setLimit(Limit - 6)
        if (count === 7) {
          setcurrent(count)
        }
      }
    }
  }, [count])

  const HandleScroll = (direction) => {
    var container = document.getElementById('pop-container-compare')

    if (direction === 'left') {
      container.scrollLeft -= 265;
      if (count > 4) {
        setCount(count - 1);
      }
    }
    else {
      container.scrollLeft += 265;
      if (count > 3) {
        setCount(count + 1);
        // console.log(count+1)
      }
    }
  }
  const handleCompareVehicles = (compare) => {
    const Analyticsprops = {
      GA: true,
      title: `Popular Vehicle Compare`,
      category: `${type === 1 ? 'Home Page' : 'Vehicles listing page'}-Events`,
      action: `Compare Vehicle Button`,
      label: `${compare?.vehicle_1?.vehicle_prefix ? compare?.vehicle_1?.vehicle_prefix + ' ' + compare?.vehicle_1?.title : compare?.vehicle_1?.title} vs ${compare?.vehicle_2?.vehicle_prefix ? compare?.vehicle_2?.vehicle_prefix + ' ' + compare?.vehicle_2?.title : compare?.vehicle_2?.title} clicked!`,
    }
    Analytics(Analyticsprops)
    const vehicleIds = [compare?.vehicle_1?.vehicle_id, compare?.vehicle_2?.vehicle_id]
    if (vehicleIds?.length > 1) {
      // dispatch(setCompareVehicle([compare?.vehicle_1,compare?.vehicle_2]));
      // dispatch({ type: SET_COMPARE_VEHICLE, payload: [compare?.vehicle_1, compare?.vehicle_2] });
      // dispatch(setCompareVehicleId(vehicleIds));
      Router.push(
        `/compare?vehicle=${vehicleIds.toString()}`
      );
      localStorage.setItem('compareVehicleId', vehicleIds.toString())
    }
  }
  const getTruncatedTitle = (vehicle) => {
    const prefixAndTitle = vehicle?.vehicle_prefix ? `${vehicle?.vehicle_prefix} ${vehicle?.title}` : vehicle?.title;
    return prefixAndTitle.length > 22 ? `${prefixAndTitle.substring(0, 22)}...` : prefixAndTitle;
  };
  const getTruncatedTitleMob = (vehicle) => {
    const prefixAndTitle = vehicle?.vehicle_prefix ? `${vehicle?.vehicle_prefix} ${vehicle?.title}` : vehicle?.title;
    return prefixAndTitle.length > 14 ? `${prefixAndTitle.substring(0, 14)}...` : prefixAndTitle;
  };
  const CompareCard = ({ compare }) => {
    return (
      <div className='Vehicle-Compare-Section flex-shrink-0'>
        <div className='vehicle-compare-container  d-flex justify-content-between align-items-center flex-row m-1' >
          <div className='vehicle-comparision d-inline-block border rounded-2'>
            <div className='d-flex justify-content-around  flex-row'>
              <div className='first-vehicle-compare-card col-md-6 '>
                {/* <img className='compare-vehicle-img m-1' src='https://images.news18.com/telugu/uploads/2023/01/ko2-1.jpg?im=Resize,width=904,aspect=fit,type=normal' /> */}
                <Link replace href={`/product/${compare?.vehicle_1?.slug}`}>
                  <Image
                    className='compare-vehicle-img m-1 c-pointer'
                    sizes="100vw"
                    loading="lazy"
                    alt='vehicle-img'
                    quality={100}
                    width={100}
                    height={73}
                    src={compare?.vehicle_1?.image}
                  /></Link>
                <Link replace href={`/product/${compare?.vehicle_1?.slug}`}>
                  <h2 className='fs-16 fw-500 mb-1 mt-2 c-pointer'>{getTruncatedTitle(compare?.vehicle_1)}</h2>
                </Link>
                {/* <StarRatings
                  rating={parseFloat(compare?.vehicle_1?.reviewsCount || 0)}
                  numberOfStars={5}
                  starDimension="15px"
                  starSpacing="0px"
                  name="rating"
                  starHoverColor="#FF9529"
                  starRatedColor="#FDCC0D"
                /><span style={{ fontSize: '12px' }}>({compare?.vehicle_1?.reviewsCount || 0})</span> */}
                <h3 className='fs-14 mt-2 mb-1 fw-500'><BiRupee />{PriceConvertor(compare?.vehicle_1?.total_price)} <span className='fs-10 color-d-gray fw-400'>Onwards</span></h3>
                {/* <p className='d-flex' style={{ fontSize: '9px', color: 'gray' }}>on road price in ahemdabad</p> */}
                <div className='d-flex justify-content-between gap-2 mt-2'>
                  <span
                    className={`text-center ${compare?.vehicle_1?.topSpeed < compare?.vehicle_2?.topSpeed ? 'spec-red' : compare?.vehicle_2?.topSpeed == compare?.vehicle_1?.topSpeed ? 'color-d-gray' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_1?.topSpeed}km/h</p>
                    <p className='fs-10 mb-1'>Top Speed</p>
                  </span>
                  <span
                    className={`text-center ${compare?.vehicle_1?.trueRange < compare?.vehicle_2?.trueRange ? 'spec-red' : compare?.vehicle_2?.trueRange == compare?.vehicle_1?.trueRange ? 'color-d-gray' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_1?.trueRange}km</p>
                    <p className='fs-10 mb-1'>True Range</p>
                  </span>
                  <span
                    className={`text-center ${compare?.vehicle_1?.waiting_period > compare?.vehicle_2?.waiting_period ? 'spec-red' : compare?.vehicle_2?.waiting_period === compare?.vehicle_1?.waiting_period ? 'color-d-gray' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_1?.waiting_period === 0 ? "1D" : compare?.vehicle_1?.waiting_period + "D"}</p>
                    <p className='fs-10 mb-1'>Waiting Period</p>
                  </span>
                </div>

              </div>
              <div className='mob-divider-line' ><span className='mob-compare-vs-round'>vs</span></div>
              <div className='second-vehicle-compare-card col-md-6'>
                {/* <img className='compare-vehicle-img m-1' src='https://images.news18.com/telugu/uploads/2023/01/ko2-1.jpg?im=Resize,width=904,aspect=fit,type=normal'/> */}
                <Link replace href={`/product/${compare?.vehicle_2?.slug}`}>
                  <Image
                    className='compare-vehicle-img m-1 c-pointer'
                    sizes="100vw"
                    loading="lazy"
                    alt='vehicle-img'
                    quality={100}
                    width={100}
                    height={69}
                    src={compare?.vehicle_2?.image}
                  />
                </Link>
                <Link replace href={`/product/${compare?.vehicle_2?.slug}`}>
                  <h2 className='fs-16 fw-500 mb-1 mt-2 c-pointer'>{getTruncatedTitle(compare?.vehicle_2)}</h2>
                </Link>
                {/* <StarRatings
                  rating={parseFloat(compare?.vehicle_2?.reviewsCount || 0)}
                  numberOfStars={5}
                  starDimension="15px"
                  // starDimension={isTablet ? "15px" : "20px"}
                  starSpacing="0px"
                  name="rating"
                  starHoverColor="#FF9529"
                  starRatedColor="#FDCC0D"
                /><span style={{ fontSize: '12px' }}>({compare?.vehicle_2?.reviewsCount || 0})</span> */}
                <h3 className='fs-14 my-2 fw-500'><BiRupee />{PriceConvertor(compare?.vehicle_2?.total_price)} <span className='fs-12 color-d-gray fw-400'>Onwards</span></h3>
                {/* <p className='d-flex' style={{fontSize:'9px', color:'gray'}}>on road price in ahemdabad</p> */}
                <div className='d-flex gap-2 justify-content-between' style={{ paddingRight: "10px" }}>
                  <span
                    className={`text-center ${compare?.vehicle_2?.topSpeed < compare?.vehicle_1?.topSpeed ? 'spec-red' : compare?.vehicle_2?.topSpeed == compare?.vehicle_1?.topSpeed ? 'color-d-gray' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_2?.topSpeed}km/h</p>
                    <p className='fs-10 mb-1'>Top Speed</p>
                  </span>
                  <span
                    className={`text-center ${compare?.vehicle_2?.trueRange < compare?.vehicle_1?.trueRange ? 'spec-red' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_2?.trueRange}km</p>
                    <p className='fs-10 mb-1'>True Range</p>
                  </span>
                  <span
                    className={`text-center ${compare?.vehicle_2?.waiting_period > compare?.vehicle_1?.waiting_period ? 'spec-red' : compare?.vehicle_2?.waiting_period === compare?.vehicle_1?.waiting_period ? 'color-d-gray' : 'spec-green'}`}
                  >
                    <p className='mb-1 fw-500'>{compare?.vehicle_2?.waiting_period === 0 ? "1D" : compare?.vehicle_2?.waiting_period + "D"}</p>
                    <p className='fs-10 mb-1'>Waiting Period</p>
                  </span>
                </div>


              </div>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center flex-row'> */}
            {/* <button className='compare-vehicle-btn d-flex m-2 align-items-center'>{compare?.title}</button> */}
            <Button
              className='compare-vehicle-btn mt-md-3 text-center fw-500 w-100'
              title="Compare Vehicles"
              click={(e) => {

                handleCompareVehicles(compare)


              }}
            />
          </div>


        </div>
      </div>
    )
  }

  const MobileVehicleCard = ({ compare }) => {
    return (
      <div style={{ height: '285px' }} className='border mt-2 mb-1 rounded-2 py-3 pe-3 ps-1'>
        <div className='d-flex'>
          <div style={{ width: 'max-content' }}>
            <Link replace href={`/product/${compare?.vehicle_1?.slug}`}>
              <Image
                className='compare-vehicle-img c-pointer'
                // sizes="100vw"
                loading="lazy"
                quality={100}
                width={100}
                height={80}
                alt='vehicle-image'
                // layout='fill'
                src={compare?.vehicle_1?.image}
              />
            </Link>
          </div>
          <div className='mob-compare-desc'>
            <Link replace href={`/product/${compare?.vehicle_1?.slug}`}>
              <h2 className='fs-16 fw-500 mb-1 c-pointer' style={{ marginBottom: '-2%' }}>{getTruncatedTitleMob(compare?.vehicle_1)}</h2>
            </Link>
            {/* <StarRatings
            rating={parseFloat(compare?.vehicle_1?.reviewsCount || 4)}
            numberOfStars={5}
            starDimension="12px"
            starSpacing="0px"
            name="rating"
            starHoverColor="#FF9529"
            starRatedColor="#FDCC0D"
          /><span style={{ fontSize: '10px' }}>({compare?.vehicle_1?.reviewsCount || 4})</span> */}
            <h3 className='fs-14 mob-compare-price'><span className='fw-500'><BiRupee />{PriceConvertor(compare?.vehicle_1?.total_price)}</span> <span className='fs-10 color-d-gray fw-400'>Onwards</span></h3>
            <div className='d-flex  mt-2'>
              <span
                className={`me-3  ${compare?.vehicle_1?.topSpeed < compare?.vehicle_2?.topSpeed ? 'spec-red' : compare?.vehicle_2?.topSpeed == compare?.vehicle_1?.topSpeed ? 'color-d-gray' : 'spec-green'}`}
              >
                <p className='mb-0 fw-500 fs-12'>{compare?.vehicle_1?.topSpeed}km/h</p>
                <p style={{ fontSize: '10px' }} className='mb-1'>Top Speed</p>
              </span>
              <span
                className={` ${compare?.vehicle_1?.trueRange < compare?.vehicle_2?.trueRange ? 'spec-red' : compare?.vehicle_2?.trueRange == compare?.vehicle_1?.trueRange ? 'color-d-gray' : 'spec-green'}`}
              >
                <p className='mb-0 fs-12 fw-500'>{compare?.vehicle_1?.trueRange}km</p>
                <p style={{ fontSize: '10px' }} className='mb-1'>True Range</p>
              </span>
              {/* <span
                    className={`text-center ${compare?.vehicle_1?.waiting_period > compare?.vehicle_2?.waiting_period ? 'spec-red' : 'spec-green'}`}
                  >
                    <p className='mb-0 fw-500'>{compare?.vehicle_1?.waiting_period === 0 ? "1D" : compare?.vehicle_1?.waiting_period + "D"}</p>
                    <p className='fs-10 mb-1'>Waiting Period</p>
                  </span> */}
            </div>
          </div>
        </div>
        <div className='mob-divider-line'>
          <span className='mob-compare-vs-round'>vs</span>
        </div>
        <div className='d-flex'>
          <div style={{ width: 'max-content' }}>
            <Link replace href={`/product/${compare?.vehicle_2?.slug}`}>
              <Image
                className='compare-vehicle-img'
                // sizes="100vw"
                loading="lazy"
                quality={100}
                width={100}
                alt='vehicle-image'
                height={80}
                // layout='fill'
                src={compare?.vehicle_2?.image}
              />
            </Link>
          </div>
          <div className='mob-compare-desc'>
            <Link replace href={`/product/${compare?.vehicle_2?.slug}`}>
              <h2 className='fs-16 fw-500 mb-1' style={{ marginBottom: '-2%' }}>{getTruncatedTitleMob(compare?.vehicle_2)}</h2>
            </Link>
            {/* <StarRatings
            rating={parseFloat(compare?.vehicle_2?.reviewsCount || 4)}
            numberOfStars={5}
            starDimension="12px"
            starSpacing="0px"
            name="rating"
            starHoverColor="#FF9529"
            starRatedColor="#FDCC0D"
          /><span style={{ fontSize: '10px' }}>({compare?.vehicle_2?.reviewsCount || 4})</span> */}
            <h3 className='fs-14 mob-compare-price'><span className='fw-500'><BiRupee />{PriceConvertor(compare?.vehicle_2?.total_price)}</span> <span className='fs-10 color-d-gray fw-400'>Onwards</span></h3>
            <div className='d-flex  mt-2'>
              <span
                className={
                  `me-3  
                      ${compare?.vehicle_2?.topSpeed < compare?.vehicle_1?.topSpeed ? 'spec-red' : compare?.vehicle_2?.topSpeed == compare?.vehicle_1?.topSpeed ? 'color-d-gray' : 'spec-green'}`}
              >
                <p className='mb-0 fw-500 fs-12'>{compare?.vehicle_2?.topSpeed}km/h</p>
                <p style={{ fontSize: '10px' }} className='mb-1'>Top Speed</p>
              </span>
              <span
                className={
                  `${compare?.vehicle_1?.trueRange > compare?.vehicle_2?.trueRange ? 'spec-red' : compare?.vehicle_2?.trueRange == compare?.vehicle_1?.trueRange ? 'color-d-gray' : 'spec-green'}`
                }
              >
                <p className='mb-0 fs-12 fw-500'>{compare?.vehicle_2?.trueRange}km</p>
                <p style={{ fontSize: '10px' }} className='mb-1'>True Range</p>
              </span>
              {/* <span
                    className={`text-center ${compare?.vehicle_2?.waiting_period > compare?.vehicle_2?.waiting_period ? 'spec-red' : 'spec-green'}`}
                  >
                    <p className='mb-0 fw-500'>{compare?.vehicle_2?.waiting_period === 0 ? "1D" : compare?.vehicle_2?.waiting_period + "D"}</p>
                    <p className='fs-10 mb-1'>Waiting Period</p>
                  </span> */}
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "12px" }}>
          <Button
            className='compare-vehicle-btn mt-md-3 text-center fw-500 w-100'
            title="View Comparison"
            click={(e) => {

              handleCompareVehicles(compare)


            }}
          />
        </div>
      </div>
    )
  }


  const renderPopularvehicles = useMemo(
    () =>
      comparevehicles?.length > 0
        ? comparevehicles?.slice(0, slicesize).map((compare) => {
          return (
            isMobile ? <MobileVehicleCard compare={compare} /> : <CompareCard compare={compare} />
          )
        })
        : null,
    [comparevehicles, vehicleLoading, slicesize]
  );
  return (
    <Fragment>
      <CommonParentSection title={'Compare Electric Vehicles'} type={"vehicleCompare"}>
        {comparevehicles?.length > 0 ? (
          <div className='position-relative scroll-container-hide mt-2'>
            <div
              className='d-flex justify-content-between align-items-center gap-3 flex-row scroll-container scroll-container-full'
              id='pop-container-compare'
            // style={{ height: '410px' }}
            >
              {renderPopularvehicles}
            </div>
            <button
              className='scroll-btn scroll-btn-left scroll-btn-position d-none d-xl-block'
              onClick={() => HandleScroll('left')}
              // style={{ transform: 'translate(0%, 400%)' }}
              aria-label="Scroll to vehicle compare on the left"
            >
              <AiOutlineLeft id='popular-vehicle-compare-left' className='Right-left-icon' />
            </button>
            <button
              className='scroll-btn scroll-btn-right scroll-btn-right-position d-none d-xl-block'
              onClick={() => HandleScroll('right')}
              // style={{ transform: 'translate(0%, -400%)' }}
              aria-label="Scroll to vehicle compare on the right"
            >
              <AiOutlineRight id='popular-vehicle-compareright' className='Right-left-icon ' />
            </button>
          </div>
        ) : (
          <HomePageVehicleLoader />
        )}
      </CommonParentSection>
    </Fragment>
  );
}

